// Импортируйте axiosInstance, если он вам нужен
import {axiosInstanceCabinet} from '../../helpers/instance';
import router from '@/router/index.js';

const state = {
  token: localStorage.getItem('authAdminToken') || null,
  info: null
};

const getters = {
  isAuthenticated: state => !!state.token
};

const actions = {
  async login({ commit }, body) {
    try {
      const response = await axiosInstanceCabinet.post('/auth/login', body);

      if (response && response.data) {
        if(response.data.data && response.data.data.access_token){
          commit('setToken', response.data.data.access_token);
          
          window.location.replace('/cabinet')
        }
      }

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async register({ commit }, body) {
    try {
      const response = await axiosInstanceCabinet.post('/auth/register', body);

      if (response && response.data) {
       
        if(response.data.data && response.data.data.token){
          commit('setToken', response.data.data.token);

          window.location.replace('/cabinet')
        }
      }

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async getInfo({ commit }) {
    try {
      axiosInstanceCabinet.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstanceCabinet.get('cabinet/user_info');

      if (response.data) {
        commit('setInfo', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async invoice({ commit }, query) {
    try {
      axiosInstanceCabinet.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstanceCabinet.get('cabinet/invoice', {
        params: query,  
      });

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async sheets({ commit }, query) {
    try {
      axiosInstanceCabinet.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstanceCabinet.get('cabinet/turnover_sheet', {
        params: query,  
      });

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  logout({ commit }) {
    commit('clearToken');
    window.location.replace('/')
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem('authAdminToken', token);
  },
  clearToken(state) {
    state.token = null;
    localStorage.removeItem('authAdminToken');
  },
  setInfo(state, data){
    state.info = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
